// production toda vez que for feito o build tanto para production quanto para staging

export const APPLICATION_VERSION =
  process.env.NODE_ENV === 'production' ? '@@APPLICATION_VERSION@@' : '';

export const GRAPHQL_SERVER =
  process.env.NODE_ENV === 'production'
    ? '@@GRAPHQL_SERVER@@'
    : // eslint-disable-next-line no-restricted-globals
    `//${location.hostname}:8000/graphql`;

export const SHOW_LINK_TO_PIPELINE_SUBSCRIPTION_ARTICLE =
  process.env.NODE_ENV === 'production'
    ? '@@SHOW_LINK_TO_PIPELINE_SUBSCRIPTION_ARTICLE@@'
    : 'false';

export const SHOW_LINK_TO_RTU_ARTICLE =
  process.env.NODE_ENV === 'production'
    ? '@@SHOW_LINK_TO_RTU_ARTICLE@@'
    : 'false';

export const API_KEY =
  process.env.NODE_ENV === 'production'
    ? '@@API_KEY@@'
    : 'MTkhcQlaryFGhaStRoKAtTOO9SzmZRAu';

export const URL =
  process.env.NODE_ENV === 'production'
    ? '@@URL@@'
    : 'https://core.stg.digibee.dev';

export const API_KEY_INTERCOM = 'adccj97f';

export const RECAPTCHA_SITE_KEY =
  process.env.NODE_ENV === 'production'
    ? '@@RECAPTCHA_SITE_KEY@@'
    : '6LdxSE4oAAAAAM-gGoYKtQkVyCb6gx8X1zO9_AGF';

export const USEPILOT_KEY = 'NX-ad6864bc';

export const EXPOSE_CYTOSCAPE =
  process.env.NODE_ENV === 'production' ? '@@EXPOSE_CYTOSCAPE@@' : 'true';

export const version =
  process.env.NODE_ENV === 'production' ? '@@VERSION@@' : '3.1';

export const DYNATRACE_APP_ID =
  process.env.NODE_ENV === 'production' ? '@@DYNATRACE_APP_ID@@' : '';

export const DATE_PIPELINE_ENGINE_METRICS_PRODUCER =
  process.env.NODE_ENV === 'production'
    ? '@@DATE_PIPELINE_ENGINE_METRICS_PRODUCER@@'
    : '1619094963000';

export const SHOW_OLD_API_KEY_TAG =
  process.env.NODE_ENV === 'production' ? '@@SHOW_OLD_API_KEY_TAG@@' : 'true';

export const DAYS_TO_SHOW_OLD_API_KEY_TAG =
  process.env.NODE_ENV === 'production'
    ? '@@DAYS_TO_SHOW_OLD_API_KEY_TAG@@'
    : '90';

export const ACTIVATE_POLLING_PERMISSONS =
  process.env.NODE_ENV === 'production'
    ? '@@ACTIVATE_POLLING_PERMISSONS@@'
    : 'true';

export const POLLING_PERMISSIONS_INTERVAL =
  process.env.NODE_ENV === 'production'
    ? '@@POLLING_PERMISSIONS_INTERVAL@@'
    : '60000';

export const PIPELINE_INTERCOM_FALLBACK_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? '@@PIPELINE_INTERCOM_FALLBACK_ENDPOINT@@'
    : 'https://test.godigibee.io/pipeline/digibee/v1/intercom-offline';

export const PIPELINE_INTERCOM_FALLBACK_APIKEY =
  process.env.NODE_ENV === 'production'
    ? '@@PIPELINE_INTERCOM_FALLBACK_APIKEY@@'
    : 'BJsazPnTK2RB6q3afWmEUsQloEhxUjhf';

export const PORTAL_URL =
  process.env.NODE_ENV === 'production'
    ? '@@PORTAL_URL@@'
    : '//{realm}.localhost:3000';

export const PORTAL_FULLSTORY_ORG_ID =
  process.env.NODE_ENV === 'production' ? '@@PORTAL_FULLSTORY_ORG_ID@@' : '';

export const FULLSTORY_RELAY =
  process.env.NODE_ENV === 'production' ? '@@FULLSTORY_RELAY@@' : 'false';

export const DATADOG_APPLICATION_ID =
  process.env.NODE_ENV === 'production' ? '@@DATADOG_APPLICATION_ID@@' : '';

export const DATADOG_CLIENT_TOKEN =
  process.env.NODE_ENV === 'production' ? '@@DATADOG_CLIENT_TOKEN@@' : '';

export const DATADOG_SITE =
  process.env.NODE_ENV === 'production'
    ? '@@DATADOG_SITE@@'
    : 'us5.datadoghq.com';

export const DATADOG_SERVICE =
  process.env.NODE_ENV === 'production'
    ? '@@DATADOG_SERVICE@@'
    : 'digibee-portal';

export const DATADOG_ENVIRONMENT =
  process.env.NODE_ENV === 'production' ? '@@DATADOG_ENVIRONMENT@@' : 'local';

export const DATADOG_SESSION_RATE =
  process.env.NODE_ENV === 'production' ? '@@DATADOG_SESSION_RATE@@' : '100';

export const DATADOG_PREMIUM_SESSION_RATE =
  process.env.NODE_ENV === 'production'
    ? '@@DATADOG_PREMIUM_SESSION_RATE@@'
    : '100';

export const FEATUREFLAG_API_KEY =
  process.env.NODE_ENV === 'production'
    ? '@@FEATUREFLAG_API_KEY@@'
    : 'localhost';

export const FEATUREFLAG_URL =
  process.env.NODE_ENV === 'production'
    ? '@@FEATUREFLAG_URL@@'
    : '//localhost:3011/api';

// eslint-disable-next-line import/first
import MockFeaturesFlag from '../jsons/features-flag.json';

export const FEATUREFLAG_MOCK_FEATURES =
  process.env.NODE_ENV === 'production' || FEATUREFLAG_API_KEY !== 'localhost'
    ? null
    : MockFeaturesFlag;

export const FUSION_VERSION =
  process.env.NODE_ENV === 'production' ? '@@FUSION_VERSION@@' : '1.47.1';

export const TOGAI_URL =
  process.env.NODE_ENV === 'production'
    ? '@@TOGAI_URL@@'
    : 'https://sandbox-billing.togai.com';

export const TOGAI_TOKEN =
  process.env.NODE_ENV === 'production'
    ? '@@TOGAI_TOKEN@@'
    : 'VJctiXrsARBjBjuIdQLJCcHgpoihtMAsXLoCwuQV';

export const TOGAI_ENV =
  process.env.NODE_ENV === 'production' ? '@@TOGAI_ENV@@' : 'sandbox';

export const TOGAI_ORGANIZATION_ID =
  process.env.NODE_ENV === 'production'
    ? '@@TOGAI_ORGANIZATION_ID@@'
    : 'VJctiXrsAR';
