import Flow, { Component, Level } from '@digibee/flow';
import { createMachine, assign } from 'xstate';

import { TreeNode } from './Node';
import flowToTree, { TreeList, treeToList } from './utils/flowToTree';
import generateExpandedById, {
  isAllCollapsed
} from './utils/generateExpandedById';

import { TriggerSpec } from '~/entities/TriggerSpec';

export type TreeContext = {
  triggerSpec?: TriggerSpec;
  flow: Flow;
  list: TreeList;
  tree: TreeNode;
  expandedById: Record<string, boolean>;
  isAllCollapsed: boolean;
  currentLevel?: Level;
  currentLevelComponent?: Component | null;
  withoutStarterNode?: boolean;
};

type TreeEvents =
  | { type: 'EXPAND_ALL' }
  | { type: 'COLLAPSE_ALL' }
  | { type: 'TOGGLE_ALL' }
  | { type: 'TOGGLE_CHILDREN'; id: string }
  | { type: 'NAVIGATE_TO'; id: string }
  | {
      type: 'SET_CURRENT_LEVEL';
      levelName: string;
    }
  | { type: 'SET_FLOW'; flow: Flow; levelName?: string }
  | { type: 'SET_TRIGGER_SPEC'; triggerSpec: TriggerSpec }
  | { type: 'LOG' };

type TreeServices = {
  generateData: {
    // The data that gets returned from the service
    data: {
      flow: Flow;
      tree: TreeNode;
      list: TreeList;
      expandedById: Record<string, boolean>;
    };
  };
};

const treeMachine = createMachine(
  {
    id: 'tree',
    tsTypes: {} as import('./Tree.machine.typegen').Typegen0,
    schema: {
      context: {} as TreeContext,
      events: {} as TreeEvents,
      services: {} as TreeServices
    },
    context: {
      triggerSpec: undefined,
      flow: new Flow({}),
      tree: {
        id: '',
        item: '',
        label: '',
        depth: 0
      },
      list: [],
      expandedById: {},
      isAllCollapsed: false,
      withoutStarterNode: false
    },
    initial: 'loading',
    states: {
      loading: {
        on: {
          SET_FLOW: {
            actions: ['setCurrentLevel', 'setFlow'],
            target: 'loading'
          }
        },
        invoke: {
          src: 'generateData',
          onDone: {
            target: 'idle',
            actions: 'assignData'
          }
        }
      },
      idle: {
        entry: ['setIsAllCollapsed'],
        on: {
          SET_FLOW: {
            actions: ['setCurrentLevel', 'setFlow'],
            target: 'loading'
          },
          SET_TRIGGER_SPEC: {
            actions: ['setTriggerSpec']
          },
          TOGGLE_CHILDREN: {
            actions: ['toggleChildren'],
            target: 'idle'
          },
          EXPAND_ALL: {
            actions: ['expandOrCollapseAll']
          },
          COLLAPSE_ALL: {
            actions: ['expandOrCollapseAll']
          },
          SET_CURRENT_LEVEL: {
            actions: ['setCurrentLevel']
          }
        }
      }
    }
  },
  {
    services: {
      generateData: ({
        flow,
        triggerSpec,
        expandedById,
        withoutStarterNode
      }) => {
        const tree = flowToTree(flow, triggerSpec, withoutStarterNode);
        return Promise.resolve({
          flow,
          tree,
          list: treeToList(tree, expandedById),
          expandedById: generateExpandedById(flow, expandedById)
        });
      }
    },
    actions: {
      assignData: assign((context, event) => ({
        flow: event.data.flow,
        tree: event.data.tree,
        list: event.data.list,
        expandedById: event.data.expandedById
      })),
      setFlow: assign({
        flow: (context, event) => event.flow
      }),
      setTriggerSpec: assign({
        triggerSpec: (_, { triggerSpec }) => triggerSpec
      }),
      setCurrentLevel: assign(
        ({ flow, currentLevel, currentLevelComponent }, event) => {
          const level = flow.getLevel(event.levelName || 'start');

          if (!level) return { currentLevel, currentLevelComponent };

          return {
            currentLevel: level,
            currentLevelComponent: level.isStart()
              ? null
              : level.parentComponent()
          };
        }
      ),
      setIsAllCollapsed: assign({
        isAllCollapsed: ({ expandedById, flow }) => {
          const val = isAllCollapsed(flow, expandedById);
          return val;
        }
      }),
      toggleChildren: assign((context, { id }) => {
        const { expandedById } = context;

        const newExpandedById = {
          ...expandedById,
          [id]: !expandedById[id]
        };

        return {
          ...context,
          expandedById: newExpandedById,
          list: treeToList(context.tree, newExpandedById)
        };
      }),
      expandOrCollapseAll: assign({
        expandedById: ({ expandedById }, { type }) =>
          Object.keys(expandedById).reduce(
            (acc, currentId) => ({
              ...acc,
              [currentId]: type === 'EXPAND_ALL'
            }),
            {}
          )
      })
    }
  }
);

export default treeMachine;
