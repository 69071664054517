import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { compose } from 'recompose';

import apiLicenseStatus from '~/api/licenseStatus';
import apiRun from '~/api/runtime';
import i18n from '~/common/helpers/i18n';
import WebAnalytics from '~/common/helpers/webAnalytics';
import withAcls from '~/common/hoc/withAcls';
import withHooks from '~/common/hoc/withHooks';
import useAcls from '~/common/hooks/useAcls';
import { useHeader } from '~/components/HeaderProvider';

const mapStateToProps = ({ application, runtime, project, license }) => ({
  loading: runtime.loading,
  loadingCreate: runtime.loadingCreate,
  activeRealm: application.activeRealm,
  environment: application.environment,
  projectById: project?.entities?.project,
  projectAllIds: project.result.project,
  deploymentDetails: runtime.deploymentDetails,
  environments: application.environments.asMutable({ deep: true }),
  fetchProjectLoading: project?.loading?.fetch,
  license: license.license,
  canvasVersion: runtime.canvasVersion
});

const mapDispatchToProps = ({ runtime, application, project, snackbar }) => ({
  remove: runtime.remove,
  create: runtime.create,
  showBetaCreateDeploy: runtime.showBetaCreateDeploy,
  onShowModalDeployment: runtime.onShowModalDeployment,
  findEnvironments: application.findEnvironments,
  setPipeline: runtime.setPipeline,
  fetchProject: project.fetch,
  reset: runtime.reset,
  snackbarCreate: snackbar.create,
  loadingDeployments: runtime.loading
});

const DEPLOYMENTS_PER_PAGE = 6;

function getStatusParamValue(status) {
  if (!status || status === 'null') return '';
  if (status?.length > 0) return status;

  return '';
}

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAcls,
  withHooks(props => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [triggers, setTriggers] = useState([]);
    const [isOpenDetailPromote, setIsOpenDetailPromote] = useState(false);
    const [isOpenRollback, setIsOpenRollback] = useState(false);
    const [isDeleteRollback, setIsDeleteRollback] = useState(false);
    const [loadingRollback, setLoadingRollback] = useState(false);
    const [deploymentSelected, setDeploymentSelected] = useState(null);
    const [currentProject, setCurrentProject] = useState();
    const [newDeploymentsPage, setNewDeploymentsPage] = useState();
    const [deploymentsPerPage, setDeploymentsPerPage] =
      useState(DEPLOYMENTS_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(0);
    const [, containScopes] = useAcls();

    const [activeView, setActiveView] = useState(
      searchParams.get('activeView') ||
        localStorage.getItem('activeView') ||
        'card'
    );

    const time = 5;

    const [timePulling, setTimePulling] = useState(parseInt(time, 10) || 5);

    const navigate = useNavigate();

    const actionsButton = [
      {
        icon: 'RocketLaunch',
        text: i18n.t('common.actions.deploy'),
        role: [
          'DEPLOYMENT:CREATE',
          `DEPLOYMENT:CREATE{ENV=${props.environment?.toUpperCase()}}`
        ],
        'data-testid': 'run-button-create',
        action: () => {
          props.showBetaCreateDeploy(true);
        }
      }
    ];

    const editPipelinePath = `/${props?.activeRealm}/design/v2/pipelines`;
    const logsPath = `/${props?.activeRealm}/operation/monitor/${props.environment}/pipeline-logs`;

    useHeader(
      {
        configActionButton: actionsButton
      },
      [props.environment]
    );

    const actions = {
      changeEnvironment: environment => {
        const statusTag = getStatusParamValue(searchParams.get('status'));
        const params = [
          `projectId=${searchParams.get('projectId') || ''}`,
          `status=${statusTag}`,
          `pipelineName=${searchParams.get('pipelineName')}`,
          `refresh=${searchParams.get('refresh')}`,
          `activeView=${activeView || searchParams.get('activeView')}`
        ].join('&');

        const url = `/${props.activeRealm}/operation/run/${environment}?${params}`;

        navigate(url, { replace: true });
      },
      goToPipeline: id =>
        window.open(
          `/${props.activeRealm}/design/v2/pipelines/${id}`,
          '_blank'
        ),
      createDeploy: () => {
        props.showBetaCreateDeploy(true);
      },
      redeploy: pipeline => {
        props.setPipeline(pipeline);
      }
    };
    useEffect(() => {
      async function getTriggers() {
        const { data } = await apiRun.getTriggers({ realm: props.activeRealm });
        setTriggers(data.triggers);
      }

      getTriggers();
    }, []);
    useEffect(() => () => props.reset(), {});

    async function getDeploymentPaginated(search, page) {
      const projectName = props.projectById[search.projectId]?.name;
      if ((projectName && search.projectId) || search.projectId === '') {
        props.loadingDeployments(true);
        const status = search?.status?.length > 0 ? search.status : [''];
        const { data } = await apiRun.findPaginated({
          realm: props.activeRealm,
          environment: props.environment,
          pipelineName: search?.pipelineName || '',
          status,
          projectName: projectName || '',
          pageSize: deploymentsPerPage,
          page: page || 0
        });

        setNewDeploymentsPage(data.deploymentsPaginated);
        props.loadingDeployments(false);
      }
    }

    useEffect(() => {
      function getRefreshValue() {
        const isValidPooling = ['5', '10', '30', 'OFF'].includes(
          searchParams.get('refresh')
        );

        if (isValidPooling) {
          return searchParams.get('refresh');
        }

        return parseInt(time, 10) || 5;
      }

      const params = {
        projectId: searchParams.get('projectId') || '',
        pipelineName: searchParams.get('pipelineName') || '',
        status: searchParams.get('status') || [],
        refresh: getRefreshValue(),
        activeView: activeView || searchParams.get('activeView')
      };

      setSearchParams(params);
      setCurrentProject(params.projectId || null);

      async function getDeployment(param) {
        await getDeploymentPaginated(param, currentPage);
      }
      getDeployment(params);
    }, [searchParams, props.projectById, activeView]);

    useEffect(() => {
      props.findEnvironments({ realm: props.activeRealm });
      props.fetchProject(null, { loading: true });
    }, [props.activeRealm]);

    function changeObjSearch(newoBJ) {
      setCurrentProject(newoBJ.search.projectId || null);
      setSearchParams(params => {
        params.set('projectId', newoBJ.search.projectId || '');
        return params;
      });
    }

    function getNameSize(name) {
      return name.split('-')[0];
    }

    async function openDetailPromote(deployment) {
      const enviromentsOptions = [];
      props.environments.allNames
        .filter(env => env !== deployment.activeConfiguration?.environment.name)
        .forEach(env => {
          if (containScopes([`DEPLOYMENT:CREATE{ENV=${env.toUpperCase()}}`])) {
            enviromentsOptions.push({
              label: env,
              value: env
            });
          }
        });
      const env = enviromentsOptions[0]?.value;
      let licenseStatus = {};
      if (env) {
        licenseStatus = await apiLicenseStatus.fetch({
          environment: env,
          realm: props.activeRealm,
          replicas: deployment.activeConfiguration.replicaCount,
          pipelineId: deployment.pipeline.id,
          size: getNameSize(deployment.activeConfiguration.name)
        });
      }

      setDeploymentSelected({
        ...deployment,
        newEnvironment: env,
        enviromentsOptions,
        pipeline: {
          ...deployment.pipeline,
          licenseStatus
        }
      });

      setIsOpenDetailPromote(true);
    }

    function closeDetailPromotion() {
      setIsOpenDetailPromote(false);
    }

    function changeUrl(obj) {
      const statusTag = getStatusParamValue(searchParams.get('status'));

      setSearchParams({
        pipelineName:
          obj?.pipelineName !== undefined
            ? obj?.pipelineName
            : searchParams.get('pipelineName'),
        projectId:
          obj?.projectId !== undefined
            ? obj.projectId || ''
            : searchParams.get('projectId') || '',
        status: obj?.status !== undefined ? obj?.status : statusTag,
        refresh:
          obj?.refresh !== undefined
            ? obj?.refresh
            : searchParams.get('refresh'),
        activeView:
          obj?.activeView !== undefined
            ? obj?.activeView
            : activeView || searchParams.get('activeView')
      });
    }

    function changePulling(newTime) {
      changeUrl({ refresh: newTime });
      setTimePulling(newTime);
    }

    async function getData(page) {
      const params = {
        projectId: searchParams.get('projectId') || '',
        pipelineName: searchParams.get('pipelineName') || '',
        pooling: searchParams.get('pooling') || '',
        projectName: props.projectById[searchParams.get('projectId')]?.name,
        status: getStatusParamValue(searchParams.get('status')),
        activeView: activeView || searchParams.get('activeView')
      };
      await getDeploymentPaginated(params, page || 0);
    }

    async function remove(deployment) {
      await props.remove(deployment);
      await getData(currentPage);
    }

    useEffect(() => {
      let intervalId;
      const getInternalData = async () => {
        WebAnalytics.sendEvent('[RUN] Polling Time', {
          time: searchParams.get('refresh')
        });

        getData(currentPage);
        if (searchParams.get('refresh') === 'OFF') {
          return;
        }

        intervalId = setInterval(
          () => getData(currentPage),
          searchParams.get('refresh') * 1000
        );
      };
      getInternalData();
      return () => clearInterval(intervalId);
    }, [
      searchParams,
      timePulling,
      deploymentsPerPage,
      props.projectById,
      currentPage
    ]);

    async function openModalRollbackDeploy(deployment) {
      setDeploymentSelected(deployment);
      const payload = {
        realm: props.activeRealm,
        deployment: deployment.id,
        environment: props.environment,
        pipelineName: deployment.pipeline.name,
        pipelineMajorVersion: deployment.pipeline.versionMajor.toString()
      };
      const { data } = await apiRun.rollbackDeployDetails(payload);

      setIsDeleteRollback(!data?.rollbackDeployDetails);
      setIsOpenRollback(true);
    }

    function closeModalRollback() {
      setIsOpenRollback(false);
    }

    async function rollbackDeploy() {
      setLoadingRollback(true);
      const payload = {
        realm: props.activeRealm,
        deployment: deploymentSelected.id,
        environment: props.environment,
        pipelineName: deploymentSelected.pipeline.name,
        pipelineMajorVersion:
          deploymentSelected.pipeline.versionMajor.toString()
      };

      try {
        await apiRun.rollbackDeploy(payload);
        setLoadingRollback(false);
        props.snackbarCreate({
          text: i18n.t('label.pipe_deploy_rollback_done_msg_success', {
            pipeName: deploymentSelected.pipeline.name
          }),
          success: true,
          open: true
        });
        await getData(currentPage);
        closeModalRollback();
      } catch (error) {
        setLoadingRollback(false);
      }
    }

    function setDefaultCurrentPage() {
      setNewDeploymentsPage([]);
      setDeploymentsPerPage(DEPLOYMENTS_PER_PAGE);
      setCurrentPage(0);
    }

    useEffect(() => {
      setDefaultCurrentPage();
    }, [activeView]);

    async function onNextPage() {
      setDeploymentsPerPage(DEPLOYMENTS_PER_PAGE);
      if (!newDeploymentsPage.last) {
        setCurrentPage(curr => curr + 1);
        await getData(currentPage + 1);
      }
    }

    async function onPreviousPage() {
      setDeploymentsPerPage(DEPLOYMENTS_PER_PAGE);
      if (!newDeploymentsPage.first) {
        setCurrentPage(curr => curr - 1);
        await getData(currentPage - 1);
      }
    }

    function onInfinityScroll() {
      setDeploymentsPerPage(deploymentsPerPage + DEPLOYMENTS_PER_PAGE);
    }

    function handleViewChange(view) {
      setDefaultCurrentPage();
      setSearchParams(params => {
        params.set('activeView', view);
        return params;
      });
      setActiveView(view);
      localStorage.setItem('activeView', view);
    }

    return {
      ...props,
      ...actions,
      activeView,
      handleViewChange,
      onNextPage,
      onPreviousPage,
      currentPage,
      changeObjSearch,
      changePulling,
      openDetailPromote,
      closeDetailPromotion,
      openModalRollbackDeploy,
      closeModalRollback,
      rollbackDeploy,
      remove,
      deploymentSelected,
      timePulling,
      editPipelinePath,
      logsPath,
      triggers,
      isOpenDetailPromote,
      current: currentProject,
      projectById: {
        ALL: {
          id: null,
          name: i18n.t('action.all_projects')
        },
        ...props.projectById
      },
      isOpenRollback,
      isDeleteRollback,
      loadingRollback,
      newDeploymentsPage,
      onInfinityScroll,
      setDefaultCurrentPage,
      changeUrl,
      selectedTags: searchParams.get('status')
        ? [searchParams.get('status')]
        : []
    };
  })
);

export default enhancer;
