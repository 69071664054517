import { useCallback, useEffect, useRef } from 'react';

const usePolling = <R = unknown>(
  fn: () => Promise<R>,
  ms: number,
  canFetch: boolean
) => {
  const runningCount = useRef(0);
  const timeout = useRef<number>();
  const mountedRef = useRef(false);

  const next = useCallback(
    (handler: TimerHandler) => {
      if (mountedRef.current && runningCount.current === 0) {
        timeout.current = window.setTimeout(handler, ms);
      }
    },
    [ms]
  );

  const isDocumentHidden = useRef(false);

  const run = useCallback(async () => {
    runningCount.current += 1;
    let result = null;
    if (!isDocumentHidden.current && canFetch) {
      result = await fn();
    }
    runningCount.current -= 1;

    next(run);

    // eslint-disable-next-line consistent-return
    return result;
  }, [fn, next, isDocumentHidden, canFetch]);

  useEffect(() => {
    function handleVisibilityChange() {
      isDocumentHidden.current = document.hidden;
    }
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    mountedRef.current = true;

    run();

    return () => {
      mountedRef.current = false;
      window.clearTimeout(timeout.current);
    };
  }, [run]);

  const flush = useCallback(() => {
    window.clearTimeout(timeout.current);
    return run();
  }, [run]);

  return flush;
};

export default usePolling;
